const createVendorDetails = ({ token, vendor, year }, { vendorRepo, observer }) => {
  vendorRepo.createVendorDetails(
    { year, vendor, token },
    {
      success: (response) => {
        const { data, errors } = response.data

        if (errors) {
          observer.failure(response.error)
          return
        }

        observer.success(data)
      },
      failure: (error) => {
        console.log('error', error)
        observer.failure(error)
      }
    }
  )
}

export default createVendorDetails
