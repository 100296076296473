const createOrUpdateSpace = (
  { year, token, element1, element2, element3, element4, estimates },
  { observer, spacesRepo }
) => {
  spacesRepo.createOrUpdateSpace(
    { year, token, element1, element2, element3, element4, estimates },
    {
      success: (response) => {
        if (response.error) {
          observer.failure(response.error)
          return
        }

        observer.success(response.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default createOrUpdateSpace
