import { createColumnHelper } from '@tanstack/react-table'
import Select from 'react-select'

const columnHelper = createColumnHelper()

const getResourceColumns = (workloads = []) => {
  const workloadOptions = workloads.map((workload) => ({ value: workload, label: workload }))
  return [
    columnHelper.accessor((row) => row, {
      id: 'select',
      header: ({ table }) => (
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => {
        const { status } = row.original

        if (!status) {
          return <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        }
        return <></>
      },
      enableSorting: false
    }),
    columnHelper.accessor((row) => row, {
      id: 'status',
      header: () => 'Status',
      cell: ({ row }) => {
        const { claimedStatus } = row.original
        if (claimedStatus) {
          return <span className="badge badge-claimed">Claimed</span>
        }
        return <span className="badge badge-open">Open</span>
      },
      enableSorting: false
    }),
    // columnHelper.display({
    //   id: 'modify',
    //   header: () => 'Modify',
    //   cell: ({ row }) => {
    //     return (
    //       <div className="action-buttons text-center">
    //         <button
    //           {...{
    //             onClick: () => setSelectedResource(row.original)
    //           }}
    //           className="btn btn-sm btn-detail "
    //         >
    //           <FontAwesomeIcon icon={faPlus} />
    //         </button>
    //       </div>
    //     )
    //   }
    // }),
    columnHelper.accessor('productCode', {
      header: () => 'Service Name'
    }),
    columnHelper.accessor('resourceId', {
      header: () => 'Resource Name',
      cell: (info) => <div className="resource-cell">{info.getValue()}</div>
    }),
    columnHelper.accessor('workload', {
      header: () => 'Workload',
      cell: ({ row }) => {
        return (
          <Select
            classNamePrefix="select"
            options={workloadOptions}
            defaultValue={workloadOptions.find((option) => option.value === row.original.claimedStatus?.reference)}
            onChange={(option) => {
              row.original.claimedStatus = {
                ...row.original.claimedStatus,
                reference: option.value
              }
            }}
          />
        )
      }
    }),
    // columnHelper.accessor('creationDate', {
    //   header: () => 'Creation Date',
    //   cell: (info) => (info.getValue() ? moment(info.getValue()).format('MM-DD-YYYY') : 'Not defined')
    // }),
    columnHelper.accessor('tags', {
      header: () => 'Tags'
    })
  ]
}

export default getResourceColumns
