import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CreditList from './CreditList'
import CreditForm from './CreditForm'

import './CreditManagement.scss'
import AppContext from 'context/AppContext'
import LoadingSpinner from 'components/common/LoadingSpinner'
import getCreditMapping from '../use-cases/getCreditMapping'
import createCreditMapping from '../use-cases/createCreditMapping'

const CreditManagement = () => {
  const { repoFactory, sorElements, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [creditMappings, setCreditMappings] = useState([])

  useEffect(() => {
    getCredit()
  }, [selectedYear, repoFactory])

  const getCredit = async () => {
    setIsLoading(true)
    getCreditMapping(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        creditRepo: repoFactory.creditRepo(),
        observer: {
          failure: (error) => {
            setIsLoading(false)
            toast.error('There was an error, please try again.')
          },
          success: (data) => {
            setIsLoading(false)
            setCreditMappings(data)
          }
        }
      }
    )
  }

  const createCredit = async (credit) => {
    setIsLoading(true)
    createCreditMapping(
      {
        token: localStorage.getItem('authToken'),
        creditMapping: credit,
        year: selectedYear
      },
      {
        creditRepo: repoFactory.creditRepo(),
        observer: {
          failure: (error) => {
            toast.error('There was an error, please try again.')
            setIsLoading(false)
          },
          success: (data) => {
            setIsLoading(false)
            toast.success('Vendor details saved successfully.')
          }
        }
      }
    )
  }

  return (
    <div className="credit-management">
      {isLoading && <LoadingSpinner />}
      {sorElements && <CreditForm sorElements={sorElements} save={createCredit} />}
      <CreditList creditMappings={creditMappings} />
    </div>
  )
}
export default CreditManagement
