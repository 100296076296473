const getCreditMapping = ({ token, year }, { creditRepo, observer }) => {
  creditRepo.getCreditMapping(
    { year, token },
    {
      success: (response) => {
        const { getCreditMapping } = response.data.data
        if (getCreditMapping.error) {
          observer.failure(response.error)
          return
        }

        observer.success(getCreditMapping.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default getCreditMapping
