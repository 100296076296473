import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import getPaginationControls from 'components/common/Utils/getPaginationControls'
import ContractForm from './ContractForm'

const columnHelper = createColumnHelper()

function ProgramTable({ items }) {
  const [data, setData] = useState(items)
  const [filteredData, setFilteredData] = useState(items)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (searchText) {
      const filtered = data.filter((item) => {
        return (
          item.mapId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.mapName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.mapType.toLowerCase().includes(searchText.toLowerCase())
        )
      })
      setFilteredData(filtered)
    } else {
      setFilteredData(data)
    }
  }, [searchText])

  const columns = [
    columnHelper.accessor('mapID', {
      header: () => 'Map ID'
    }),
    columnHelper.accessor('mapName', {
      header: () => 'Map Name'
    }),
    columnHelper.accessor('mapType', {
      header: () => 'Map Type'
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            <button className="btn btn-sm btn-remap" onClick={() => {}}>
              Remap
            </button>

            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail"
            >
              Details{' '}
              {row.getIsExpanded() ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </button>
          </div>
        )
      }
    })
  ]

  const table = useReactTable({
    data: filteredData,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  return (
    <>
      <Row className="mb-3">
        <Col md={4}>
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search ..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <table className="table table-bordered">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <tr>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr className="expanded-row">
                  <td colSpan={row.getVisibleCells().length}>
                    <ProgramDetail data={row.original} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  )
}

const ProgramDetail = ({ data }) => {
  return (
    <Card>
      <Card.Body>
        <ContractForm readOnly={true} />
      </Card.Body>
    </Card>
  )
}

export default ProgramTable
