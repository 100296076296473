const getVendorDetails = ({ token, year }, { vendorRepo, observer }) => {
  vendorRepo.getVendorDetails(
    { year, token },
    {
      success: (response) => {
        const { getVendorDetails } = response.data.data
        if (getVendorDetails.error) {
          observer.failure(getVendorDetails.error)
          return
        }

        observer.success(getVendorDetails.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default getVendorDetails
