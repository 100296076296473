const fakeAccountIdsData = [
  {
    value: '140-167-905',
    label: '140-167-905'
  },
  {
    value: '140-167-906',
    label: '140-167-906'
  },
  {
    value: '140-167-907',
    label: '140-167-907'
  },
  {
    value: '140-167-908',
    label: '140-167-908'
  },
  {
    value: '140-167-909',
    label: '140-167-909'
  },
  {
    value: '140-167-910',
    label: '140-167-910'
  },
  {
    value: '140-167-911',
    label: '140-167-911'
  },
  {
    value: '140-167-912',
    label: '140-167-912'
  },
  {
    value: '140-167-913',
    label: '140-167-913'
  },
  {
    value: '140-167-914',
    label: '140-167-914'
  },
  {
    value: '140-167-915',
    label: '140-167-915'
  },
  {
    value: '140-167-916',
    label: '140-167-916'
  },
  {
    value: '140-167-917',
    label: '140-167-917'
  },
  {
    value: '140-167-918',
    label: '140-167-918'
  },
  {
    value: '140-167-919',
    label: '140-167-919'
  },
  {
    value: '140-167-920',
    label: '140-167-920'
  },
  {
    value: '140-167-921',
    label: '140-167-921'
  },
  {
    value: '140-167-922',
    label: '140-167-922'
  },
  {
    value: '140-167-923',
    label: '140-167-923'
  },
  {
    value: '140-167-924',
    label: '140-167-924'
  },
  {
    value: '140-167-925',
    label: '140-167-925'
  },
  {
    value: '140-167-926',
    label: '140-167-926'
  },
  {
    value: '140-167-927',
    label: '140-167-927'
  },
  {
    value: '140-167-928',
    label: '140-167-928'
  },
  {
    value: '140-167-929',
    label: '140-167-929'
  },
  {
    value: '140-167-930',
    label: '140-167-930'
  },
  {
    value: '140-167-931',
    label: '140-167-931'
  },
  {
    value: '140-167-932',
    label: '140-167-932'
  },
  {
    value: '140-167-933',
    label: '140-167-933'
  },
  {
    value: '140-167-934',
    label: '140-167-934'
  },
  {
    value: '140-167-935',
    label: '140-167-935'
  },
  {
    value: '140-167-936',
    label: '140-167-936'
  },
  {
    value: '140-167-937',
    label: '140-167-937'
  },
  {
    value: '140-167-938',
    label: '140-167-938'
  },
  {
    value: '140-167-939',
    label: '140-167-939'
  },
  {
    value: '140-167-940',
    label: '140-167-940'
  },
  {
    value: '140-167-941',
    label: '140-167-941'
  },
  {
    value: '140-167-942',
    label: '140-167-942'
  },
  {
    value: '140-167-943',
    label: '140-167-943'
  },
  {
    value: '140-167-944',
    label: '140-167-944'
  },
  {
    value: '140-167-945',
    label: '140-167-945'
  },
  {
    value: '140-167-946',
    label: '140-167-946'
  },
  {
    value: '140-167-947',
    label: '140-167-947'
  },
  {
    value: '140-167-948',
    label: '140-167-948'
  },
  {
    value: '140-167-949',
    label: '140-167-949'
  },
  {
    value: '140-167-950',
    label: '140-167-950'
  },
  {
    value: '140-167-951',
    label: '140-167-951'
  },
  {
    value: '140-167-952',
    label: '140-167-952'
  },
  {
    value: '140-167-953',
    label: '140-167-953'
  },
  {
    value: '140-167-954',
    label: '140-167-954'
  },
  {
    value: '140-167-955',
    label: '140-167-955'
  },
  {
    value: '140-167-956',
    label: '140-167-956'
  },
  {
    value: '140-167-957',
    label: '140-167-957'
  },
  {
    value: '140-167-958',
    label: '140-167-958'
  },
  {
    value: '140-167-959',
    label: '140-167-959'
  }
]

export default fakeAccountIdsData
