import { createColumnHelper } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const columnHelper = createColumnHelper()

const getColumns = () => {
  return [
    columnHelper.accessor('legalEntity', {
      header: () => 'Legal Entity'
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date'
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date'
    }),
    columnHelper.accessor('type', {
      header: () => 'Type'
    }),
    columnHelper.accessor('amount', {
      header: () => 'Amount'
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Actions',
      cell: ({ row }) => {
        return (
          <div className="action-buttons">
            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail "
            >
              Details{' '}
              {row.getIsExpanded() ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </button>
          </div>
        )
      }
    })
  ]
}

export default getColumns
