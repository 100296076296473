function VendorRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getVendorDetails = ({ token, year }, { success, failure }) => {
    const query = `query getVendorDetails($token: String!, $year: Int!) {
      getVendorDetails(token: $token, year: $year) {
        error {
          code
          message
          statusCode
        }
        data {
          year
          mapping {
            appliedRate {
              endDate
              startDate
              value
            }
            createdDate
            discountRate {
              endDate
              startDate
              value
            }
            spendTarget {
              endDate
              startDate
              value
            }
            userView
            username
          }
        }
      }
    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createVendorDetails = ({ token, vendor, year }, { success, failure }) => {
    const query = `mutation ($token: String!, $vendor: VendorDiscountDetailsInput!, $year: Int!) {
      createVendorDetails(token: $token, vendor: $vendor, year: $year) {
        data {
          year
          mapping {
            appliedRate {
              endDate
              startDate
              value
            }
            createdDate
            discountRate {
              endDate
              startDate
              value
            }
            spendTarget {
              endDate
              startDate
              value
            }
            userView
            username
          }
        }
        error {
          code
          message
          statusCode
        }
      }
    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            vendor,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default VendorRepo
