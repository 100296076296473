import React, { Fragment, useContext } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'

import fakeData from './fakeData'
import getColumns from './MarketplaceColumn'
import AppContext from 'context/AppContext'

const columnHelper = createColumnHelper()

function Marketplace() {
  const { sorElementMapping } = useContext(AppContext)

  const table = useReactTable({
    data: fakeData,
    columns: getColumns(),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <tr>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr className="expanded-row">
                  <td colSpan={row.getVisibleCells().length}>
                    <DetailsTable details={row.original.details} sorElementMapping={sorElementMapping} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const DetailsTable = ({ details, sorElementMapping }) => {
  const columns = [
    columnHelper.accessor('element1', {
      header: () => sorElementMapping.element1
    }),
    columnHelper.accessor('element2', {
      header: () => sorElementMapping.element2
    }),
    columnHelper.accessor('element3', {
      header: () => sorElementMapping.element3
    }),
    columnHelper.accessor('element4', {
      header: () => sorElementMapping.element4
    }),
    columnHelper.accessor('percentage', {
      header: () => 'Percentage'
    }),
    columnHelper.accessor('reference', {
      header: () => 'Reference'
    }),
    columnHelper.accessor('effectiveDates', {
      header: () => 'Effective Dates'
    })
  ]

  const table = useReactTable({
    data: details,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  table.getHeaderGroups().map((headerGroup) => console.log('headerGroup', headerGroup))

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Marketplace
