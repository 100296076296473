import { useRef, useState } from 'react'
import ProgramTable from './ProgramTable'
import LoadingSpinner from 'components/common/LoadingSpinner'
import fakeProgramData from './fakeProgramData'

import './Program.scss'
import ContractForm from './ContractForm'
import { Card, Modal } from 'react-bootstrap'

const Program = () => {
  const formRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const save = async () => {
    const form = formRef.current
    await form?.submitForm()
    if (form?.isValid) {
      console.log(form.values)
    }
  }

  return (
    <div className="vendor-program">
      {isLoading && <LoadingSpinner />}
      <div className="mb-3">
        <button type="button" className="btn btn-primary btn-sm" onClick={handleShow}>
          Create
        </button>
      </div>

      <ProgramTable items={fakeProgramData} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <h5>Create New Contract</h5>
          <Card className="section-gray mb-3">
            <Card.Body>
              <ContractForm formEl={formRef} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary btn-sm" onClick={handleClose}>
            Close
          </button>
          <button type="button" className="btn btn-primary btn-sm" onClick={save}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default Program
