import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import inviteUserUseCase from '../use-cases/invite-user-use-case'
import Roles from 'config/Roles'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Select from 'react-select'
import RolesMap from 'config/RolesMap'
import { toast } from 'react-toastify'

const InviteUser = (props) => {
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)
  const [roleOptions] = useState(
    Object.values(Roles).map((role) => ({
      label: RolesMap[role],
      value: role
    }))
  )

  useEffect(() => {
    if (username && email && role) {
      setCreateButtonDisabled(false)
    } else {
      setCreateButtonDisabled(true)
    }
  }, [username, email, role])

  const handleSubmit = () => {
    setIsLoading(true)
    inviteUserUseCase(
      {
        username,
        email,
        role
      },
      {
        userRepo: props.repoFactory.userRepo(),
        observer: {
          successfulInviteOfUser: () => {
            setError(false)
            setIsLoading(false)
            setUsername('')
            setEmail('')
            setRole(null)
            props.getAllUsers()
            toast.success('User invited successfully', {
              hideProgressBar: true
            })
          },
          errorWhileInvitingUser: () => {
            setError(true)
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <div className="mb-4">
      {error && (
        <Alert variant={'danger'}>
          Error saving user, please validate username contains no spaces and that email not previously in use
        </Alert>
      )}

      {isLoading && <LoadingSpinner />}

      <h1>Invite User</h1>
      <Card className="mb-4">
        <Card.Body>
          <Form className="row">
            <Form.Group className="col-md-4 mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="username"
                placeholder="Enter an username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
              />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter an email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3">
              <Form.Label>Role</Form.Label>
              <Select
                placeholder="Select a role"
                options={roleOptions}
                onChange={(e) => setRole(e.value || null)}
                value={roleOptions.find((option) => option.value === role)}
                className="react-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Form>
          <div className="text-end">
            <Button variant="primary" type="button" disabled={createButtonDisabled} onClick={handleSubmit}>
              Invite User
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default InviteUser
