const fakeProgramData = [
  {
    mapID: '417-699-36',
    mapName: 'Map 1',
    mapTypes: ['Standard MAP'],
    accountIDs: ['140-167-905', '140-167-906'],
    startDate: '2024-01-01',
    term: '1 Year',
    spend: 1000,
    credit: 10,
    tagKey: 'map-migrated',
    tagValue: '417-699-36'
  },
  {
    mapID: '417-699-37',
    mapName: 'Map 2',
    mapTypes: ['Windows Workloads', 'From Non-Commercial DB', 'SAP'],
    accountIDs: ['140-167-907', '140-167-908', '140-167-909'],
    startDate: '2024-02-01',
    term: '3 Year',
    spend: 2000,
    credit: 15,
    tagKey: 'map-migrated',
    tagValue: '417-699-37'
  },
  {
    mapID: '417-699-38',
    mapName: 'Map 3',
    mapTypes: ['From Non-Commercial DB'],
    accountIDs: ['140-167-910', '140-167-911'],
    startDate: '2024-03-01',
    term: '1 Year',
    spend: 1500,
    credit: 20,
    tagKey: 'map-migrated',
    tagValue: '417-699-38'
  },
  {
    mapID: '417-699-39',
    mapName: 'Map 4',
    mapTypes: ['From Commercial DB', 'SAP'],
    accountIDs: ['140-167-912'],
    startDate: '2024-04-01',
    term: '3 Year',
    spend: 2500,
    credit: 5,
    tagKey: 'map-migrated',
    tagValue: '417-699-39'
  },
  {
    mapID: '417-699-40',
    mapName: 'Map 5',
    mapTypes: ['SAP'],
    accountIDs: ['140-167-913', '140-167-914'],
    startDate: '2024-05-01',
    term: '1 Year',
    spend: 3000,
    credit: 25,
    tagKey: 'map-migrated',
    tagValue: '417-699-40'
  },
  {
    mapID: '417-699-41',
    mapName: 'Map 6',
    mapTypes: ['Private Equity', 'Windows Workloads'],
    accountIDs: ['140-167-915', '140-167-916', '140-167-917'],
    startDate: '2024-06-01',
    term: '3 Year',
    spend: 3500,
    credit: 30,
    tagKey: 'map-migrated',
    tagValue: '417-699-41'
  },
  {
    mapID: '417-699-42',
    mapName: 'Map 7',
    mapTypes: ['Standard MAP'],
    accountIDs: ['140-167-918'],
    startDate: '2024-07-01',
    term: '1 Year',
    spend: 4000,
    credit: 35,
    tagKey: 'map-migrated',
    tagValue: '417-699-42'
  },
  {
    mapID: '417-699-43',
    mapName: 'Map 8',
    mapTypes: ['Windows Workloads', 'From Non-Commercial DB', 'Private Equity'],
    accountIDs: ['140-167-919', '140-167-920'],
    startDate: '2024-08-01',
    term: '3 Year',
    spend: 4500,
    credit: 40,
    tagKey: 'map-migrated',
    tagValue: '417-699-43'
  },
  {
    mapID: '417-699-44',
    mapName: 'Map 9',
    mapTypes: ['From Non-Commercial DB'],
    accountIDs: ['140-167-921'],
    startDate: '2024-09-01',
    term: '1 Year',
    spend: 5000,
    credit: 45,
    tagKey: 'map-migrated',
    tagValue: '417-699-44'
  },
  {
    mapID: '417-699-45',
    mapName: 'Map 10',
    mapTypes: ['From Commercial DB', 'SAP', 'Standard MAP'],
    accountIDs: ['140-167-922', '140-167-923', '140-167-924'],
    startDate: '2024-10-01',
    term: '3 Year',
    spend: 5500,
    credit: 50,
    tagKey: 'map-migrated',
    tagValue: '417-699-45'
  },
  {
    mapID: '417-699-46',
    mapName: 'Map 11',
    mapTypes: ['SAP'],
    accountIDs: ['140-167-925'],
    startDate: '2024-11-01',
    term: '1 Year',
    spend: 6000,
    credit: 55,
    tagKey: 'map-migrated',
    tagValue: '417-699-46'
  },
  {
    mapID: '417-699-47',
    mapName: 'Map 12',
    mapTypes: ['Private Equity', 'Windows Workloads'],
    accountIDs: ['140-167-926', '140-167-927'],
    startDate: '2024-12-01',
    term: '3 Year',
    spend: 6500,
    credit: 60,
    tagKey: 'map-migrated',
    tagValue: '417-699-47'
  },
  {
    mapID: '417-699-48',
    mapName: 'Map 13',
    mapTypes: ['Standard MAP'],
    accountIDs: ['140-167-928'],
    startDate: '2025-01-01',
    term: '1 Year',
    spend: 7000,
    credit: 65,
    tagKey: 'map-migrated',
    tagValue: '417-699-48'
  },
  {
    mapID: '417-699-49',
    mapName: 'Map 14',
    mapTypes: ['Windows Workloads', 'SAP', 'From Non-Commercial DB'],
    accountIDs: ['140-167-929', '140-167-930'],
    startDate: '2025-02-01',
    term: '3 Year',
    spend: 7500,
    credit: 70,
    tagKey: 'map-migrated',
    tagValue: '417-699-49'
  },
  {
    mapID: '417-699-50',
    mapName: 'Map 15',
    mapTypes: ['From Non-Commercial DB'],
    accountIDs: ['140-167-931', '140-167-932'],
    startDate: '2025-03-01',
    term: '1 Year',
    spend: 8000,
    credit: 75,
    tagKey: 'map-migrated',
    tagValue: '417-699-50'
  },
  {
    mapID: '417-699-51',
    mapName: 'Map 16',
    mapTypes: ['From Commercial DB', 'Standard MAP'],
    accountIDs: ['140-167-933', '140-167-934', '140-167-935'],
    startDate: '2025-04-01',
    term: '3 Year',
    spend: 8500,
    credit: 80,
    tagKey: 'map-migrated',
    tagValue: '417-699-51'
  },
  {
    mapID: '417-699-52',
    mapName: 'Map 17',
    mapTypes: ['SAP'],
    accountIDs: ['140-167-936'],
    startDate: '2025-05-01',
    term: '1 Year',
    spend: 9000,
    credit: 85,
    tagKey: 'map-migrated',
    tagValue: '417-699-52'
  },
  {
    mapID: '417-699-53',
    mapName: 'Map 18',
    mapTypes: ['Private Equity', 'From Non-Commercial DB'],
    accountIDs: ['140-167-937', '140-167-938'],
    startDate: '2025-06-01',
    term: '3 Year',
    spend: 9500,
    credit: 90,
    tagKey: 'map-migrated',
    tagValue: '417-699-53'
  },
  {
    mapID: '417-699-54',
    mapName: 'Map 19',
    mapTypes: ['Standard MAP'],
    accountIDs: ['140-167-939'],
    startDate: '2025-07-01',
    term: '1 Year',
    spend: 10000,
    credit: 95,
    tagKey: 'map-migrated',
    tagValue: '417-699-54'
  },
  {
    mapID: '417-699-55',
    mapName: 'Map 20',
    mapTypes: ['Windows Workloads', 'SAP', 'Private Equity'],
    accountIDs: ['140-167-940', '140-167-941', '140-167-942'],
    startDate: '2025-08-01',
    term: '3 Year',
    spend: 10500,
    credit: 100,
    tagKey: 'map-migrated',
    tagValue: '417-699-55'
  },
  {
    mapID: '417-699-56',
    mapName: 'Map 21',
    mapTypes: ['From Non-Commercial DB'],
    accountIDs: ['140-167-943'],
    startDate: '2025-09-01',
    term: '1 Year',
    spend: 11000,
    credit: 5,
    tagKey: 'map-migrated',
    tagValue: '417-699-56'
  },
  {
    mapID: '417-699-57',
    mapName: 'Map 22',
    mapTypes: ['From Commercial DB', 'Standard MAP'],
    accountIDs: ['140-167-944', '140-167-945'],
    startDate: '2025-10-01',
    term: '3 Year',
    spend: 11500,
    credit: 10,
    tagKey: 'map-migrated',
    tagValue: '417-699-57'
  },
  {
    mapID: '417-699-58',
    mapName: 'Map 23',
    mapTypes: ['SAP'],
    accountIDs: ['140-167-946', '140-167-947'],
    startDate: '2025-11-01',
    term: '1 Year',
    spend: 12000,
    credit: 15,
    tagKey: 'map-migrated',
    tagValue: '417-699-58'
  },
  {
    mapID: '417-699-59',
    mapName: 'Map 24',
    mapTypes: ['Private Equity', 'Windows Workloads'],
    accountIDs: ['140-167-948'],
    startDate: '2025-12-01',
    term: '3 Year',
    spend: 12500,
    credit: 20,
    tagKey: 'map-migrated',
    tagValue: '417-699-59'
  },
  {
    mapID: '417-699-60',
    mapName: 'Map 25',
    mapTypes: ['Standard MAP'],
    accountIDs: ['140-167-949', '140-167-950', '140-167-951'],
    startDate: '2026-01-01',
    term: '1 Year',
    spend: 13000,
    credit: 25,
    tagKey: 'map-migrated',
    tagValue: '417-699-60'
  },
  {
    mapID: '417-699-61',
    mapName: 'Map 26',
    mapTypes: ['Windows Workloads', 'From Non-Commercial DB'],
    accountIDs: ['140-167-952'],
    startDate: '2026-02-01',
    term: '3 Year',
    spend: 13500,
    credit: 30,
    tagKey: 'map-migrated',
    tagValue: '417-699-61'
  },
  {
    mapID: '417-699-62',
    mapName: 'Map 27',
    mapTypes: ['From Non-Commercial DB'],
    accountIDs: ['140-167-953', '140-167-954'],
    startDate: '2026-03-01',
    term: '1 Year',
    spend: 14000,
    credit: 35,
    tagKey: 'map-migrated',
    tagValue: '417-699-62'
  },
  {
    mapID: '417-699-63',
    mapName: 'Map 28',
    mapTypes: ['From Commercial DB', 'SAP'],
    accountIDs: ['140-167-955'],
    startDate: '2026-04-01',
    term: '3 Year',
    spend: 14500,
    credit: 40,
    tagKey: 'map-migrated',
    tagValue: '417-699-63'
  },
  {
    mapID: '417-699-64',
    mapName: 'Map 29',
    mapTypes: ['SAP'],
    accountIDs: ['140-167-956', '140-167-957'],
    startDate: '2026-05-01',
    term: '1 Year',
    spend: 15000,
    credit: 45,
    tagKey: 'map-migrated',
    tagValue: '417-699-64'
  },
  {
    mapID: '417-699-65',
    mapName: 'Map 30',
    mapTypes: ['Private Equity', 'Standard MAP', 'Windows Workloads'],
    accountIDs: ['140-167-958', '140-167-959'],
    startDate: '2026-06-01',
    term: '3 Year',
    spend: 15500,
    credit: 50,
    tagKey: 'map-migrated',
    tagValue: '417-699-65'
  }
]

export default fakeProgramData
