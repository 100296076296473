const createCreditMapping = ({ token, creditMapping, year }, { creditRepo, observer }) => {
  creditRepo.createCreditMapping(
    { year, creditMapping, token },
    {
      success: (response) => {
        if (response.data.errors) {
          observer.failure(response.error)
          return
        }

        observer.success(response.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default createCreditMapping
