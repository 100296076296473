function CreditRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getCreditMapping = ({ token, year }, { success, failure }) => {
    const query = `query MyQuery($token: String!, $year: Int!) {
        getCreditMapping(token: $token, year: $year) {
          error {
            code
            message
            statusCode
          }
          data {
              promotionCode
              year
              creditMapping {
                amount
                endDate
                reference
                startDate
                allocations {
                  breakdown {
                    amount
                    element1Id
                    element2Id
                    element3Id
                    element4Id
                  }
                  endDate
                  startDate
                }
              }
            }
        }
      }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createCreditMapping = ({ token, creditMapping, year }, { success, failure }) => {
    const query = `mutation MyMutation ($token: String!, $creditMapping: CreditMappingInput!, $year: Int!) {
        createCreditMapping(token: $token, creditMapping: $creditMapping, year: $year) {
          error {
            statusCode
            message
            code
          }
          data {
              promotionCode
              year
              creditMapping {
                amount
                endDate
                reference
                startDate
                allocations {
                  breakdown {
                    amount
                    element1Id
                    element2Id
                    element3Id
                    element4Id
                  }
                  endDate
                  startDate
                }
              }
            }
        }
      }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            creditMapping,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default CreditRepo
