import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import fakeAccountIdsData from './fakeAccountIdsData'
import { useFormik } from 'formik'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect } from 'react'

const mapTypeOptions = [
  {
    value: 'Standard MAP',
    label: 'Standard MAP'
  },
  {
    value: 'Windows Workloads',
    label: 'Windows Workloads'
  },
  {
    value: 'From Non-Commercial DB',
    label: 'From Non-Commercial DB'
  },
  {
    value: 'From Commercial DB',
    label: 'From Commercial DB'
  },
  {
    value: 'SAP',
    label: 'SAP'
  },
  {
    value: 'Private Equity',
    label: 'Private Equity'
  }
]

const termOptions = [
  {
    value: '1 Year',
    label: '1 Year'
  },
  { value: '3 Years', label: '3 Years' }
]

const validate = (values) => {
  const errors = {}
  if (!values.mapID) {
    errors.mapID = ERROR_MESSAGES.REQUIRED
  }

  return errors
}

const ContractForm = ({ formEl = null, readOnly = false }) => {
  const formik = useFormik({
    initialValues: {
      mapID: '',
      mapName: '',
      mapTypes: [],
      accountIDs: [],
      startDate: new Date(),
      term: '',
      spend: '',
      credit: '',
      tagKey: 'map-migrated',
      tagValue: ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    if (formEl) {
      formEl.current = formik
    }
  }, [formik])

  console.log('formik', formik.values)
  return (
    <form onSubmit={formik.handleSubmit} className="program-form mb-3">
      <div className="row g-3">
        <div className="col-4 mb-3">
          <label htmlFor="mapID" className="form-label">
            MAP ID
          </label>
          <input
            type="text"
            id="mapID"
            name="mapID"
            className="form-control"
            value={formik.values.mapID}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-4 mb-3">
          <label htmlFor="mapName" className="form-label">
            MAP Name
          </label>
          <input
            type="text"
            id="mapName"
            name="mapName"
            className="form-control"
            value={formik.values.mapName}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-4 mb-3">
          <label className="form-label">MAP Type</label>
          <Select
            classNamePrefix="select"
            options={mapTypeOptions}
            value={formik.values.mapTypes}
            onChange={(options) => {
              formik.setFieldValue('mapTypes', options)
            }}
            isMulti
          />
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label">Accounts</label>
        <Select
          classNamePrefix="select"
          options={fakeAccountIdsData}
          value={formik.values.accountIDs}
          onChange={(options) => {
            formik.setFieldValue('accountIDs', options)
          }}
          isMulti
        />
      </div>

      <div className="row g-3">
        <div className="col-4 mb-3">
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <DatePicker
            showIcon
            autoComplete="off"
            id="startDate"
            name="startDate"
            className="form-control"
            selected={formik.values.startDate}
            onChange={(date) => {
              console.log(date)
              formik.setFieldValue('startDate', date)
            }}
          />
        </div>
        <div className="col-4 mb-3">
          <label className="form-label">Term</label>
          <Select
            classNamePrefix="select"
            options={termOptions}
            value={formik.values.term}
            onChange={(option) => {
              formik.setFieldValue('term', option)
            }}
          />
        </div>
        <div className="col-4 mb-3">
          <label htmlFor="spend" className="form-label">
            Spend
          </label>
          <CurrencyInput
            id="spend"
            name="spend"
            prefix="$"
            placeholder="$0.00"
            className="form-control"
            value={formik.values.spend}
            onValueChange={(value) => formik.setFieldValue('spend', value)}
            decimalsLimit={2}
          />
        </div>
      </div>

      <div className="row g-3">
        <div className="col-4 mb-3">
          <label htmlFor="credit" className="form-label">
            Credit
          </label>
          <div className="input-group">
            <input
              type="text"
              id="credit"
              name="credit"
              className="form-control"
              value={formik.values.credit}
              onChange={formik.handleChange}
              placeholder="0"
              maxLength={3}
            />
            <span className="input-group-text" id="basic-addon1">
              %
            </span>
          </div>
        </div>
        <div className="col-4 mb-3">
          <label htmlFor="tagKey" className="form-label">
            Tag Key
          </label>
          <input
            type="text"
            id="tagKey"
            name="tagKey"
            className="form-control"
            value={formik.values.tagKey}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-4 mb-3">
          <label htmlFor="tagValue" className="form-label">
            Tag Value
          </label>
          <input
            type="text"
            id="tagValue"
            name="tagValue"
            className="form-control"
            value={formik.values.tagValue}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </form>
  )
}
export default ContractForm
