import { useEffect } from 'react'
import { useFormik } from 'formik'
import Select from 'react-select'

import ERROR_MESSAGES from 'utils/errorMessages'

const mapTypeOptions = [
  {
    value: 'Standard MAP',
    label: 'Standard MAP'
  },
  {
    value: 'Windows Workloads',
    label: 'Windows Workloads'
  },
  {
    value: 'From Non-Commercial DB',
    label: 'From Non-Commercial DB'
  },
  {
    value: 'From Commercial DB',
    label: 'From Commercial DB'
  },
  {
    value: 'SAP',
    label: 'SAP'
  },
  {
    value: 'Private Equity',
    label: 'Private Equity'
  }
]

const validate = (values) => {
  const errors = {}
  if (!values.mapID) {
    errors.mapID = ERROR_MESSAGES.REQUIRED
  }

  if (!values.mapName) {
    errors.mapName = ERROR_MESSAGES.REQUIRED
  }

  if (!values.mapTypes.length) {
    errors.mapTypes = ERROR_MESSAGES.REQUIRED
  }

  if (!values.tagValue) {
    errors.tagValue = ERROR_MESSAGES.REQUIRED
  }

  return errors
}

const ProgramForm = ({ formEl = null, item = null }) => {
  const formik = useFormik({
    initialValues: {
      mapID: '',
      mapName: '',
      mapTypes: [],
      accountIDs: [],
      startDate: new Date(),
      term: '',
      spend: '',
      credit: '',
      tagKey: 'map-migrated',
      tagValue: ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    if (formEl) {
      formEl.current = formik
    }
  }, [formik])

  useEffect(() => {
    if (item) {
      formik.setValues({
        ...item
      })
    }
  }, [item])

  return (
    <form onSubmit={formik.handleSubmit} className="mb-3">
      <div className="row g-3">
        <div className="col-4 mb-3">
          <label htmlFor="mapID" className="form-label">
            MAP ID
          </label>
          <input
            type="text"
            id="mapID"
            name="mapID"
            className="form-control"
            value={formik.values.mapID}
            onChange={formik.handleChange}
          />
          {formik.touched.mapID && formik.errors.mapID ? <div className="text-error">{formik.errors.mapID}</div> : null}
        </div>
        <div className="col-4 mb-3">
          <label htmlFor="mapName" className="form-label">
            MAP Name
          </label>
          <input
            type="text"
            id="mapName"
            name="mapName"
            className="form-control"
            value={formik.values.mapName}
            onChange={formik.handleChange}
          />
          {formik.touched.mapName && formik.errors.mapName ? (
            <div className="text-error">{formik.errors.mapName}</div>
          ) : null}
        </div>
        <div className="col-4 mb-3">
          <label className="form-label">MAP Type(S)</label>
          <Select
            classNamePrefix="select"
            options={mapTypeOptions}
            value={formik.values.mapTypes.map((type) => mapTypeOptions.find((option) => option.value === type))}
            onChange={(options) => {
              formik.setFieldValue(
                'mapTypes',
                options.map((option) => option.value)
              )
            }}
            isMulti
          />
          {formik.touched.mapTypes && formik.errors.mapTypes ? (
            <div className="text-error">{formik.errors.mapTypes}</div>
          ) : null}
        </div>
      </div>

      <div className="row g-3">
        <div className="col-4 mb-3">
          <label htmlFor="tagValue" className="form-label">
            Tag Value
          </label>
          <input
            type="text"
            id="tagValue"
            name="tagValue"
            className="form-control"
            value={formik.values.tagValue}
            onChange={formik.handleChange}
          />
          {formik.touched.tagValue && formik.errors.tagValue ? (
            <div className="text-error">{formik.errors.tagValue}</div>
          ) : null}
        </div>
      </div>
    </form>
  )
}
export default ProgramForm
