import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

const validate = (values) => {
  const errors = {}

  if (!values.vendor) {
    errors.vendor = 'Required'
  }

  if (!values.internal) {
    errors.internal = 'Required'
  }

  if (!values.purchaseOrder) {
    errors.purchaseOrder = 'Required'
  }

  if (!values.invoiceNumber) {
    errors.invoiceNumber = 'Required'
  }

  return errors
}

function InvoiceForm({ formEl }) {
  const formik = useFormik({
    initialValues: {
      vendor: '',
      internal: '',
      purchaseOrder: '',
      invoiceNumber: ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  useEffect(() => {
    formEl.current = formik
  }, [formik])

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Row>
        <Col md={6}>
          <div className="mb-3 row">
            <label htmlFor="ar_vendor" className="col-lg-2 col-form-label">
              Vendor
            </label>
            <div className="col-lg-10">
              <input
                type="text"
                name="vendor"
                className="form-control"
                id="ar_vendor"
                onChange={formik.handleChange}
                value={formik.values.vendor}
              />
            </div>
            {formik.touched.vendor && formik.errors.vendor ? (
              <div className="text-danger">{formik.errors.vendor}</div>
            ) : null}
          </div>
          <div className="mb-3 row">
            <label htmlFor="ar_po" className="col-lg-2 col-form-label">
              PO
            </label>
            <div className="col-lg-10">
              <input
                type="text"
                name="purchaseOrder"
                className="form-control"
                id="ar_po"
                onChange={formik.handleChange}
                value={formik.values.purchaseOrder}
              />
            </div>
            {formik.touched.purchaseOrder && formik.errors.purchaseOrder ? (
              <div className="text-danger">{formik.errors.purchaseOrder}</div>
            ) : null}
          </div>
          <div className="mb-3 row">
            <label htmlFor="ar_invoice" className="col-lg-2 col-form-label">
              Invoice #
            </label>
            <div className="col-lg-10">
              <input
                type="text"
                name="invoiceNumber"
                className="form-control"
                id="ar_invoice"
                onChange={formik.handleChange}
                value={formik.values.invoiceNumber}
              />
            </div>
            {formik.touched.invoiceNumber && formik.errors.invoiceNumber ? (
              <div className="text-danger">{formik.errors.invoiceNumber}</div>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3 row">
            <label htmlFor="ar_internal" className="col-lg-2 col-form-label">
              Internal
            </label>
            <div className="col-lg-10">
              <input
                type="text"
                name="internal"
                className="form-control"
                id="ar_internal"
                onChange={formik.handleChange}
                value={formik.values.internal}
              />
            </div>

            {formik.touched.internal && formik.errors.internal ? (
              <div className="text-danger">{formik.errors.internal}</div>
            ) : null}
          </div>
        </Col>
      </Row>
    </form>
  )
}
export default InvoiceForm
