import React, { Fragment, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import Roles from 'config/Roles'
import LoadingSpinner from 'components/common/LoadingSpinner'
import changeUserRoleUseCase from '../use-cases/change-user-role-case'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import getPaginationControls from 'components/common/Utils/getPaginationControls'
import RolesMap from 'config/RolesMap'
import { toast } from 'react-toastify'

const columnHelper = createColumnHelper()

const ListUsers = ({ repoFactory, users, getAllUsers }) => {
  const roles = Object.values(Roles).map((role) => ({
    value: role,
    label: RolesMap[role]
  }))

  const [data, setData] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [error, setError] = useState(null)
  const [rolesOptions, setRolesOptions] = useState(roles)
  const [isLoading, setIsLoading] = useState(false)
  const [sorting, setSorting] = useState([])

  const columns = [
    columnHelper.accessor('Username', {
      header: () => 'Username'
    }),
    {
      id: 'Email',
      accessorFn: (row) => {
        const { Attributes } = row
        return Attributes?.find((attribute) => attribute.Name === 'email')?.Value
      }
    },
    {
      id: 'Role',
      header: 'Role',
      cell: ({ row }) => {
        const { Attributes } = row.original

        return (
          <>
            {RolesMap[Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value]}
            <button className="btn btn-sm btn-outline-primary btn-table" onClick={() => setSelectedUser(row.original)}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          </>
        )
      }
    },
    columnHelper.accessor('UserStatus', {
      header: () => 'Status'
    })
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const handleClose = () => {
    setSelectedRole(null)
    setSelectedUser(null)
    setError(null)
  }

  useEffect(() => {
    const currentRole = selectedUser?.Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value
    const newRolesOptions = roles.filter((role) => role.value !== currentRole)
    setRolesOptions(newRolesOptions)
  }, [selectedUser])

  useEffect(() => {
    const items = [...users]
    if (searchText) {
      const search = searchText.trim().toUpperCase()
      const filteredItems = items.filter((item) => {
        const { Attributes } = item
        const email = Attributes?.find((attribute) => attribute.Name === 'email')?.Value
        const role = Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value
        return (
          item.Username.toUpperCase().indexOf(search) > -1 ||
          email.toUpperCase().indexOf(search) > -1 ||
          role.toUpperCase().indexOf(search) > -1 ||
          item.UserStatus.toUpperCase().indexOf(search) > -1
        )
      })
      setData(filteredItems)
    } else {
      setData(items)
    }
  }, [users, searchText])

  const changeUserRole = () => {
    if (selectedRole) {
      setIsLoading(true)
      changeUserRoleUseCase(
        { username: selectedUser.Username, role: selectedRole },
        {
          userRepo: repoFactory.userRepo(),
          observer: {
            successfulChangeUserRole: () => {
              setError(false)
              setIsLoading(false)
              handleClose()
              getAllUsers()
              toast.success('User role changed successfully', {
                hideProgressBar: true
              })
            },
            errorWhileChangeUserRole: () => {
              setError('Error while changing user role')
              setIsLoading(false)
            }
          }
        }
      )
    } else {
      setError('Please select a new role')
    }
  }

  return (
    <div>
      <h1>Users List</h1>
      {isLoading && <LoadingSpinner />}
      <Row className="mb-2">
        <Col md={4}>
          <input
            type="text"
            value={searchText}
            className="form-control"
            placeholder="Search by username, email, role o status"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <p className="mb-1 text-muted text-sm">Total users: {data.length} </p>
      <div className="table-responsive">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {data.length === 0 && searchText ? (
              <tr>
                <td colSpan={table.getAllColumns().length} className="text-center">
                  No records found
                </td>
              </tr>
            ) : null}
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
        {getPaginationControls(table)}
      </div>

      {selectedUser ? (
        <Modal
          className="update-role-modal"
          show={selectedUser}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group mb-3">
                <label htmlFor="username">Username</label>
                <input id="username" value={selectedUser.Username} className="form-control" disabled />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="current_role">Current Role</label>
                <input
                  id="current_role"
                  value={
                    RolesMap[selectedUser?.Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value]
                  }
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="new_role">New Role</label>
                <Select
                  options={rolesOptions}
                  placeholder="Choose the new role"
                  onChange={(option) => {
                    setError(null)
                    setSelectedRole(option.value)
                  }}
                />
                {error && <span className="text-danger">{error}</span>}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={changeUserRole}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  )
}

export default ListUsers
